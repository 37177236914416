.form input,
.form textarea,
.form .p-calendar,
.form .p-dropdown.p-component {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

div.header-actions {
  margin-top: -73px !important;
  float: right;
  display: flex;
  margin-right: 3px;
}

div.card > input {
  display: block;
  width: 99%;
  margin: .5%;
}

.p-button-secondary {
  //border: 1px solid #0002 !important;
  background: #0002 !important;
}

.card p {
  padding: 20px;
  margin-top: 0;
  opacity: .5;
  text-align: center;
}

.quotes {
  overflow-x: auto;
  white-space: nowrap;
  border-radius: 3px;
  padding-bottom: 40px;
  width: 100%;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  opacity: .8;
}

.quotes::-webkit-scrollbar-thumb {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #A7BD4F;
}

.quote .header-wrapper {
  margin-bottom: 9px;
  margin-right: 2px;
}

.quote input.header {
  width: calc(100% - 190px);
}

.quote {
  border-radius: 3px;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  width: 500px;
}

.quote:last-of-type {
  margin-right: 0px;
}

.quote-fixed {
  padding: 6px 0;
}

.quote-fixed .p-inputgroup > input {
  width: 60px;
}

button.fixed-add {
  width: 100% !important;
  margin-bottom: 3px;
}

.quote-fixed .p-inputgroup {
  display: inline-block;
  margin-right: 10px;
}

.quote-fixed .p-inputgroup:last-of-type {
  margin-right: 0;
  width: 65%;
  white-space: nowrap;
}

.quote-fixed .p-inputgroup:last-of-type input {
  width: 100%;
}

.form .p-inputgroup > input {
  margin-bottom: 0 !important;
}

.form .p-inputgroup {
  margin-bottom: 10px;
}

.muted {
  opacity: 0.4;
}

.status-paid, .status-success {
  color: #0a0;
  font-weight: bolder;
}

.status-awaiting_payment, .status-danger {
  font-weight: bolder;
  color: #a00;
}

.layout-sidebar-dark .layout-menu li a:hover {
  color: #bc4;
  background: #0002;
}

.layout-sidebar-dark .layout-menu li.active-menuitem > a {
  background: inherit;
}

.layout-sidebar-dark .layout-menu li a {
  background: #0000;
  transition: 0.0s;
}

.layout-sidebar-dark .layout-menu li a.active-route, .layout-sidebar-dark .layout-menu li a:active {
  color: #bc4;
  background: #0005;
}

.layout-menu li a i {
  font-size: 2.5em;
}

.layout-topbar {
  background: #a6bf49;
}

button {
  font-size: 1.2em;
}

table button span.pi {
  font-size: 2.3em;
}

body .p-dialog .p-dialog-titlebar {
  padding: 0.6em 0.5em;
  padding-left: 1.1em;
}

.p-dialog .p-dialog-titlebar-icon span {
  font-size: 2em;
}

body .p-dialog .p-dialog-titlebar .p-dialog-title {
  font-size: 1.4em;
}

@media (max-width: 992px) {
  .layout-topbar .p-dropdown.p-component {
    margin: 10px !important;
    position: fixed;
    top: 0;
    width: auto !important;
    right: 0px;
    left: 60px;
  }
  .p-datatable-wrapper {
    overflow-x: auto;
    max-width: 100vw;
  }
  .p-datatable-wrapper table {
    width: auto !important;
  }
  .p-datatable-wrapper table button {
    margin: 5px !important;
  }
}

body input.p-component:disabled,
body input.p-disabled {
  opacity: 1 !important;
}

table button.p-component:disabled,
table button.p-disabled,
.header-actions button.p-component:disabled,
.header-actions button.p-disabled {
  display: none !important;
}

table.table tr:nth-of-type(2n) {
  background: #eee;
}

table.table {
  width: 100%;
}

table.table td {
  padding: 8px 8px;
}

.layout-main > .p-grid > .p-col-12 > .card h1 {
  margin-bottom: 20px;
  margin-top: 0;
}

.layout-main > .p-grid > .p-col-12 > .card {
  padding-top: 1em;
  padding-bottom: 2em;
}

.p-progressbar-value.p-progressbar-value-animate {
  background: #444;
}

.layout-menu > li > a span {
  font-size: 15px;
}

body .p-dialog .p-dialog-footer {
  padding: 0;
}

body .p-dialog .p-dialog-footer button {
  margin: 9px;
  margin-right: 0;
}

body .p-dialog .p-dialog-footer button:last-of-type {
  margin-right: 9px
}

body .p-dialog .p-dialog-footer button {
  border: 0px solid red;
  padding: .3em 1.1em;
  font-size: 1.2em;
}

.form input, .form .p-inputgroup-addon, .form .p-dropdown .p-dropdown-label {
  font-size: 1.2em;
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  padding: 1em .8em;
  font-size: 1.1em;
}

table td div .p-button {
}

body .p-button-labelicon {
  margin-bottom: 4px;
  display: block;
  width: 100%;
}

body .p-button-labelicon:last-of-type {
  margin-bottom: 0;
}

.p-button-labelicon .p-button-text {
  margin-left: 2px;
}

.p-button-labelicon .pi {
  margin-left: -3px;
  font-size: 21px;
}

div.divider {
  margin: 9px -12px;
  border-bottom: 1px solid #0002;
}

.opacity {
  opacity: .5;
}

.colorProjectActive * {
  color: #000;
  font-weight: bolder;
}

.colorProjectArchive * {
  color: #0004;
}

tr .p-button-success *, tr .p-button-danger *, tr .p-button-primary * {
  color: white;
}

tr .nobold * {
  font-weight: normal;
}

.p-datatable td.top {
  vertical-align: top;
}

.data-table-row.ticket {
  background: rgb(34, 67, 88);
  color: white;
}

.data-table-row.ticket * {
  border-color: #fff5;
}

.data-table-row.ticket a {
  color: white;
}

.data-table-row.ticket .p-button {

  border-radius: 8px;
  border-width: 1px;
  padding: 4px 8px;
  background: none;
  margin-right: 5px;
}

.data-table-row.ticket .p-button:hover {
  background: #fff2;
  color: white;
  border-color: #fff7;
}

.firstline-bold div, .firstline-bold::first-line {
  font-weight: bold
}

.subtitle, h2 {
  background: #0001;
  padding: 15px;
  margin-bottom: 10px;
  margin-top: -10px;
}

h1, .subtitle, h2 {
  margin: 0 !important;
}

.subtitle {
  background: #37567922;
  background: #fff;
  border-bottom: 1px solid #0002;
}

h2 {
  margin-bottom: 15px !important;
}

* {
  outline: none !important
}

button.p-button.notactive {
  background-color: #375679
}

.p-button {
  transition: 0s !important;
}

body .projectbar {
  width: auto;
  position: absolute;
  left: 0;
  right: 50px;
  top: 0;
  height: 50px;
  border: none;
  border-radius: 0;
  background: none;
  padding-left: 10px;
  background-color: none;
  box-shadow: none !important;
}

button, button:focus {
  box-shadow: none !important;
  outline: none !important;
}

body .projectbar .p-dropdown-label.p-inputtext {
  height: 50px;
  padding: 8px 10px;
  border: none;
  background-color: none;
  background: none;
  border-radius: 8px;
  padding-left: 60px;
  font-size: 1.5em;
  color: white;
}

body .projectbar .p-dropdown-trigger {
  right: auto;
  left: 25px;
  background-color: none;
  color: white;
  background: none;
}

body .projectbar.loading .p-dropdown-trigger {
  opacity: 0;

}

body .projectbar:hover .p-dropdown-label::after {
  content: " (Klicken, um Projekt zu wechseln)"
}

body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  background: #375679;
}

body .projectbar:hover {
  background: #0002;
}

button.layout-menu-button {
  display: none !important;
}

div.header-actions .p-button {
  margin-left: 1px;
}

span.wifi-icon {
  height: 50;
  width: 50;
}

@media (max-width: 992px) {

  div.card {
    padding-bottom: 20px !important;
  }
  div.card:last-of-type {
    padding-bottom: 0px !important;
  }
  div.header-actions {
    float: none;
    margin-top: -10px !important;
    margin-top: -20px !important;
    margin-bottom: 17px !important;
    margin-bottom: 0px !important;
  }
  div.header-actions .p-button {
    padding: 0;
    background: #A6BF49;
    background: #4C6988;
    background: #ddd;
    color: #555;
  }
  div.header-actions .p-button {
    margin-left: 0px;
  }
  .card h1 {
    font-size: 1.7rem !important;
  }
  .layout-main {
    padding: 0;
    padding-top: 50px;
  }
  .layout-topbar .p-dropdown.p-component {
    left: 50px;
    right: 50px;
    margin: 0 !important;
  }
  body .projectbar .p-dropdown-trigger {
    left: 10px;
  }
  body .projectbar .p-dropdown-label.p-inputtext {
    overflow: hidden;
    border-right: 20px solid transparent;
    padding-left: 35px;
    padding-top: 14px;
    font-size: 15px;
  }
  button.layout-menu-button {
    display: block !important;
    width: 50px;
    position: fixed;
    border-radius: 0;
    left: 0;
    top: 0;
    height: 50px;
    text-align: center;
    background: #fff3;
  }
  .layout-topbar .layout-menu-button:hover {
    color: white;
  }
  .layout-sidebar {
    width: 100%;
    transition: left 0.1s !important;
  }
  .layout-wrapper .layout-sidebar {
    left: -100%;
  }
  .layout-profile {
    margin: 0;
    padding: 0;
  }
  .layout-profile img {
    display: none;
  }
  .layout-profile button {
    text-align: center;
    padding: 15px;
    margin: 0 !important;
  }
  .layout-topbar i.pi.pi-spinner {
    margin-left: 35.7px !important;
  }

  /* enlargen whole navbar in mobile view*/
  .layout-topbar .layout-menu-button span {
    font-size: 3.0em;
  }
  button.layout-menu-button, span.wifi-icon {
    height: 60px;
    width: 60px;
  }
  span.wifi-icon {
    font-size: 1.2em;
  }
  .layout-topbar .p-dropdown.p-component {
    left: 60px;
    right: 60px;
  }
  body .projectbar {
    height: 60px;
  }
  body .projectbar .p-dropdown-label.p-inputtext {
    padding-top: 18px;
  }
  .layout-topbar {
    height: 60px;
  }
  .layout-wrapper .layout-sidebar {
    margin-top: 60px;
  }
  .layout-main {
    padding-top: 60px;
  }
  .projectbar div.p-dropdown-panel {
    left: -60px !important;
  }
  /* end of enlargending */

  .layout-sidebar .p-link {
    border-radius: 0;
  }
  .layout-profile button {
    text-align: left;
  }
  .layout-sidebar .layout-profile .layout-profile-expanded {
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
  }

  .layout-sidebar .layout-menu {
    border-top: none !important;
  }


  .p-dialog {
    transform: none;
    left: 0;
    right: 0;
    border-radius: 0;
    border: none;
    bottom: 0;
    background: white;
    height: 100vh;
    top: 0;
  }
  body .p-dialog .p-dialog-footer {
    border: none;
    position: absolute;
    padding-top: 3px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .p-dialog .p-dialog-content textarea {
    width: 100% !important;
  }
  .p-dialog .p-dialog-content {
    overflow: scroll;
  }
  body .p-dialog .p-dialog-footer button {
    margin: 0 1px !important;
    display: inline-block;
    margin-bottom: 3px !important;
    width: 49vw;
  }
  body .p-dialog .p-dialog-footer {
    text-align: center;
  }
  body .p-button.p-button-text-icon-left .p-button-icon-left {
    margin-right: 10px !important;
  }
  body .p-button.p-button-text-icon-left .p-button-text {
    padding: 10px 5px;
  }
  .p-dialog-titlebar-icons {
    position: absolute;
    right: 0;
    padding: 5px;
    top: 0;
  }
  .quotes {
    padding: 2.5vw;
  }
  .quote {
    width: 95vw;
  }
  .card {
    margin-bottom: 0
  }
  .p-dropdown-panel {
    position: absolute;
    z-index: 999;
    top: 50px;
    width: 100vw;
    left: -50px !important;
    border: none !important;
    box-shadow: 0 4000px 0px 4000px #0002 !important;
  }
  button.fullblock {
    display: block !important;
    width: 98.5vw !important;
    left: .5vw !important;
  }
  .p-dropdown-items-wrapper {
    max-height: calc(100vh - 50px) !important;
  }


}

.p-dropdown-items-wrapper {
  max-height: 80vh !important;
}

body .p-dialog .p-dialog-footer button {
  border: none !important;
}

.layout-menu-container {
  padding-bottom: 50px;
}

.p-grid {
  margin: 0;
}

.p-grid > div {
  padding: 0;
}

.card h1 {
  border-bottom: none;
}

.text-center {
  text-align: center
}

.card h2 {
  padding: 10px;
  text-align: center;
}

body .p-datatable .p-datatable-tbody > tr > td {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

body .p-datatable .p-datatable-thead > tr > th {
  border: none;
}

.card {
  padding: 0 !important;
  box-shadow: 0 3px 3px #0002;
}

.p-dropdown-panel .p-dropdown-filter-container {
  display: block
}

a {
}

.layout-sidebar {

  background: white;

  .layout-profile {
    .p-link.layout-profile-link {
      padding: .3em .6em;
      margin: 0
    }

    .layout-profile-expanded {
      border-top: 1px solid #ddd;
      margin-top: 9px;
      padding-top: 8px;
      margin-bottom: -10px;
    }

    ul li button {
      padding: 0.5em 0.4em;
    }

    button.p-link {
      transition: color 0s;
    }

    button.p-link:active {
      outline: 0 !important;
    }

    button.p-link:hover {
      color: #A6BF49;
      background: #A6BF49;
      color: white;
    }
  }

  .layout-menu {
    border-top: 1px solid #eee;

    li a {
      color: #333;
      transition: color 0s;
    }

    li a:hover {
      background: #0002;
    }

    li > a.active-route {
      background: #375679;
      background: #A6BF49;
      color: white;
    }

  }
}

.card div li {
  margin: 0;
  list-style: none;
}

.card h2 {
  margin-bottom: 0 !important;
}

.card h3 {
  margin: 0;
  margin-top: 1em;
}

.card div.small-list {
  margin: .7em;
  margin-bottom: 15px;
  margin-top: -10px;
}

.data-table-mobile > div {
  padding: 5px 0;
  border-top: 15px solid #EDF0F5;
}

.data-table-mobile > div > div {
  padding: 10px 15px;
  border-bottom: 1px dashed #0002;
  width: 100% !important;
}

.data-table-mobile > div > div:last-of-type {
  width: auto !important;
  display: inline-block !important;
  border-bottom: none;
}

.textfield {
  box-shadow: inset 0 0 13px #0002;
  border: 1px solid #0002;
  margin: 10px 0;
  padding: 15px 13px;
}


.hover-to-show div {
  display: none
}

.hover-to-show:focus textarea {
  display: block
}

.d-flex {
  display: flex;
  margin: 0 -.25em
}

.d-flex > * {
  margin: 0 .25em
}

ul.layout-menu > li > a > i {
}

ul.layout-menu > li > a {
  display: flex;
  align-items: center;
}

ul.layout-menu > li > a > img {
  height: 2.5em;
  width: 3.19em;

  object-fit: contain;
}

ul.layout-menu > li > a.active-route > img {
  filter: invert(1)
}

button.p-button, body .p-inputtext {
  padding: .4em .8em;
  border-radius: 8px;
  border: 2px solid #0003;
  border-width: 2px !important;
}

button.p-button-icon-only {
  padding: .1em .1em;
  font-size: 15px !important;
}

button.p-button-secondary {
  border-color: gray !important;
}

button.p-button-secondary:hover {
  border-color: #999 !important;
  background: #ccc !important;
}

body .p-button-primary, body .p-button-secondary {
  border-color: transparent;
}
