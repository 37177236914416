body {
    background-size: cover;
    background-attachment: fixed;
}

html, body, div#root {
    height: auto !important;
}

div.card {
    background: #fffe;
}

.login {
    border-radius: 0;
}

.login input, .login button {
    font-size: 1.9em;
}

.login button {
    width: 100%;
    background: #325778;
    margin-top: 5px;
    border-radius: 0;
    margin-bottom: 10px;
}

body .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
}

h1 {
    background: #375679;
    color: White;
    padding: 20px 20px !important;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 2.4em !important;
}

h4 {
    background: rgb(44, 121, 168);
    padding: 10px;
    color: White;
    display: block;
    margin-bottom: 0;

}

.card {
}

.form {
    padding: 10px 20px;
}


.p-col-6:first-of-type > div.card {
    margin-right: 15px;
}

@media (max-width: 992px) {
    body {
        background: white !important;
    }

    input, button {
        font-size: 1.2em !important;
    }

    h1 {
        padding: .4em !important;
        margin-bottom: .6em !important;
        text-align: center;
    }

    button {
        width: 100%;
        text-align: center;
        display: block;
    }

    .p-col-12.p-lg-6 {
        padding: 0 !important;
        margin-top: 0 !important;
    }

    .p-col-12.p-lg-6 .card {
        border-radius: 0 !important;
        box-shadow: none !important;
    }

    .p-col-6 {
        width: 100%;
    }

    .p-col-6:first-of-type > div.card {
        margin-right: 0;
    }
}


.header-actions {
}

.header-actions .p-button {
    padding: 10px 10px;
    font-size: 20px;
    background: #0003;
    border: none;
    border-radius: 8px;
    border: 2px solid #3333;
    margin: 0 5px;
    text-transform: uppercase;
}

.header-actions a button.p-button:hover {
    background: #0004;
    border-right: 1px solid #0000;
}


/** DARK THEME **/
@media (prefers-color-scheme: dark) {
    .header-actions .p-button:hover {
        border: none;
    }

    .layout-wrapper {
        background: #111;
        padding: 0;
        min-height: 100vh;
    }

    .layout-sidebar {
        background: #fff;
        background: #333;
        color: white;
    }

    .layout-sidebar .layout-menu li a {
        color: #333;
        color: #eee;
        transition: color 0s;
    }

    ul.layout-menu > li > a > img {
        filter: invert(1);
    }

    .colorProjectActive * {
        color: white
    }

    body .p-dropdown-panel .p-dropdown-items .p-dropdown-item, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        color: #eee;
    }

    .layout-topbar {
        background: #a6bf49;
        background: #444;
    }

    .layout-sidebar .layout-profile .p-link.layout-profile-link {
        padding: .3em .6em;
        margin: 0;
        color: white;
    }

    .layout-sidebar .layout-menu li > a.active-route {
        background: #375679;
        background: #a6bf49;
        background: #202020;
        color: #fff;
    }

    div.card {
        background: hsla(0, 0%, 4%, 0.93);
        background: hsla(0, 0%, 21%, 0.93);
        color: white;
    }


    div.subtitle, .subtitle, h2 {
        background: #0004;
        border-bottom: none;
        color: #ddd;
    }

    .data-table-mobile > div {
        border-color: #0003;
    }

    h1 {
        background: #233549;
        background: #658830;
        padding: 20px !important;
        text-align: center;
        text-transform: uppercase;
        font-size: 2.4em !important;
    }

    .layout-main > .p-grid > .p-col-12 > .card {
        padding-top: 1em;
        padding-bottom: 2em;
        color: white;
    }

    td button.p-button-secondary > span.pi, body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
        color: #fff;
    }

    td button.p-button-secondary:hover > span.pi {
    }


    body .p-datatable .p-datatable-tbody > tr > td {
        border-color: #111
    }

    body .p-button:enabled:hover {
        background-color: #7c9b0e;
        border-color: #fff4;
    }


    body .p-datatable .p-datatable-thead > tr > th {
        background-color: #222;
        color: white;
    }

    body .p-datatable .p-datatable-tbody > tr > td {

        background-color: #333;
        color: white;
    }

    .layout-sidebar .layout-profile button.p-link {

        color: white;
    }

    .layout-sidebar .layout-menu, .layout-sidebar .layout-profile .layout-profile-expanded {
        border-top-color: #0008
    }

    body .p-card {
        background: #444;
    }

    .quotes .p-inputtext {
        background: #585858;
    }

    .quotes button.p-button:hover {
        color: white !important;
        background: #222 !important;
    }

    .layout-main > .p-grid > .p-col-12 > div:nth-child(2):not(.card) {
        background: none !important
    }

    td button.p-button-secondary > span.pi {
        color: #fff
    }

    body .p-inputtext {
        font-size: 14px;
        color: #ffffff;
        background: #474747;
    }


    button.p-button-secondary:hover {
        border-color: #777 !important;
    }

    button.p-button, body .p-inputtext {
        border: 2px solid #fff3;
    }

    button.p-button:hover, body .p-inputtext:hover, body .p-inputtext:enabled:hover {
        border: 2px solid #fff4;
    }

    body .p-dialog .p-dialog-content {
        color: #333333;
        background: none;
        color: #fff;
        border: 1px solid #444;
        padding: 1em;
    }

    body .p-dialog .p-dialog-titlebar {
        border: 1px solid #c8c8c8;
        color: #333333;
        border: 1px solid #444;
        background: none;
        color: #fff;
        padding: 1em;
        font-weight: 700;
        border-bottom: 0 none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    body .p-button-primary, body .p-button-secondary {
        border-color: transparent;
    }

    body a {
        color: #88cbff;
        text-decoration: none;
    }

    #root, html, body {
        background: #111
    }

    body .p-dialog {
        padding: 0;
        border-radius: 3px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        background: #222;
        /* color: white; */
    }

    body .p-dialog .p-dialog-footer {
        border: 1px solid #444;
        border-top: 0 none;
        color: #333333;
        background: none;
        color: #eee;
        padding: 0.571em 1em;
        margin: 0;
        text-align: right;
    }

    body .p-inputtext:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2em #999;
        border-color: white;
    }

    .layout-sidebar .layout-menu {
        border: none !important
    }

    body .p-dropdown-panel .p-dropdown-filter-container {
        padding: 0.429em 0.857em 0.429em 0.857em;
        border-bottom: 1px solid #444;
        color: #333333;
        background-color: #333;
        margin: 0;
    }

    body .p-inputgroup .p-inputgroup-addon,
    body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover {
        color: #fff;
        border: none;
        background-color: #333 !important;
    }

    .layout-menu > li:last-child > a {
        border: none
    }

    body .p-dialog .p-dialog-footer button,
    body .p-dropdown,
    body .p-dropdown-panel,
    body .p-dropdown .p-dropdown-trigger, body .p-dropdown-panel,
    body .layout-topbar .p-dropdown-panel .p-dropdown-items .p-dropdown-item, body .layout-topbar .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        border: none;
        background: #444;
        color: white;
    }

    body .p-dialog .p-dialog-footer button {
        background: #333 !important;
        border: none
    }

    body .p-button {
        background: #111;
    }

    body .p-dialog .p-dialog-footer button:hover, body .p-button:hover {
        background: #222;
    }

    body .p-dialog .p-dialog-footer button:hover {
        background: #222 !important;
    }

    body .p-dialog .p-dialog-footer button:hover * {
        color: White;
    }

    .layout-profile img {
        border-radius: 50%;
        border: 5px solid #fff4;
    }

    body .p-dialog .p-dialog-content {
        background: #6b6b6b;
        color: #fff;
        border: none;
    }

    body .p-dialog .p-dialog-footer {
        border: none;
        border-top: 0 none;
        background: #4e4e4e;
        color: #eee;
    }

    body .p-dialog .p-dialog-titlebar {
        border: none;
        background: #8baf3b;
        color: #fff;
    }

    .p-dialog .p-dialog-titlebar-icon span {
        color: white;
    }

    .p-dialog .p-dialog-titlebar-icon:hover span {
        color: #fffa;
    }

    table.table tr:nth-of-type(2n) {
        background: #3c3c3c
    }
}

.p-dropdown-item .careicon {
    display: inline-block
}

.leadinfo {
    background: #9b2;
    color: white;
}

@font-face {
    font-family: "Futura", sans-serif;
    src: url("https://assets.ziegenhagel.com/fonts/futura_normal.ttf");
}

* {
    font-family: Futura;
}

