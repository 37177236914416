@import "./_variables";
@import "./sass/_layout";
@import "./_overrides";


/* Inline #5 | http://doml1:3000/#/invoices */
.p-growl.p-growl-topright {
   top: auto !important;
  bottom: 20px !important;
}
